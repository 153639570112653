.register-screen-wrapper {
  max-height: calc(100vh - 100px);
}
.session-history-wrapper {
  max-height: calc(100vh - 100px);
}
.open-register-heading {
  font-size: 2.5em !important;
  font-family: "neue-june-medium";
}

.open-register-sub-heading {
  opacity: 0.5;
  font-size: 1.3em !important;
  font-family: "neue-june-medium";
}

.open-register-button {
  font-size: 1.1em !important;
}

.last-closure-heading {
  font-size: 1.2rem;
  color: $LIGHT_GREY_TEXT_COLOR;
  font-weight: 600;
}

.register-details-heading {
  color: $BLACK_COLOR !important;
}

.last-closure-detail-heading {
  font-family: "neue-june-demi";
  font-size: 1.05em;
}

.session-summary-wrapper {
  width: 100%;
  max-width: 100%;
  border-radius: 0.5em;
  overflow: hidden;
  background-color: $WHITE_COLOR;
  border: 1px solid rgb(0, 0, 0, 0.15);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);

  .session-summary-detail-wrapper {
    display: none;
  }

  @media print {
    .session-summary-detail-wrapper {
      display: block;
    }
  }

  .session-summary-table {
    width: 100%;

    tr {
      border-bottom: 1px solid rgb(0, 0, 0, 0.15);

      th {
        padding: 1em 1.5em;
        width: 20%;
        max-width: 20%;

        span {
          display: flex;
          justify-content: end;
          align-items: center;
          width: 100%;
          font-family: "neue-june-demi";
          font-size: 1.1em;
        }
      }
      th:first-child {
        //   width: 40% !important;
        //   max-width: 40% !important;
        span {
          justify-content: start;
        }
      }
    }

    .session-summary-footer-total-row {
      border-bottom: none !important;
      th {
        span {
          font-family: "neue-june-regular";
        }
      }

      th:first-child {
        span {
          font-family: "neue-june-demi";
        }
      }
    }

    .session-summary-data-row {
      border-bottom: 1px solid rgb(0, 0, 0, 0.15) !important;
      transition: 0.3s all ease;
      &:hover {
        background-color: $LIGHT_BLUE_BG_COLOR;
      }
    }
    .session-summary-sub-data-row {
      border-bottom: 1px solid rgb(0, 0, 0, 0.15) !important;
      th:first-child span {
        font-family: "neue-june-regular" !important;
      }
    }
    .expanded-row {
      background-color: $LIGHT_BLUE_BG_COLOR;
    }
    .row-opened {
    }
    .row-closed {
    }
    .session-summary-transactions-detail-wrapper {
      max-height: 10em !important;
      overflow: hidden;
      background-color: $LIGHT_SMOKE_BLUE_BG_COLOR !important;

      .expanded-row-heading {
        font-size: 1.1em;
        height: 100%;
        min-height: 100%;
        padding-top: 1em !important;
      }

      .expanded-row-second-heading {
        font-size: 1.1em;
        font-family: "neue-june-demi";
        padding: 0.5em 1.36em;
        display: flex;
        align-items: center;
        min-height: 4em !important;
        height: 4em !important;
        max-height: 4em !important;
      }

      .expanded-row-detail {
        font-size: 1.1em;
        padding: 0.5em 1.36em;
        border-top: 1px solid rgb(0, 0, 0, 0.1) !important;
        display: flex;
        align-items: center;
        min-height: 4em !important;
        height: 4em !important;
        max-height: 4em !important;

        // &:nth-child(n-1) {
        //   border-bottom: none !important;
        // }
      }

      .transaction-view-icon {
        width: 1.6em;
        height: 1.6em;
      }
    }
  }
}
.activity-detail {
  font-size: 0.9em;
  opacity: 0.7;
}
