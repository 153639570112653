.form-check-label {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.03em;
  color: rgba(35, 34, 38, 0.8);
  margin-left: 6px;
}

.form-switch {
  margin-bottom: 0px !important;
  padding-left: 0px !important;
  display: flex !important;
  flex-direction: row-reverse !important;
  gap: 12px;
}

.form-check-input {
  height: 24px !important;
  width: 40px !important;
  margin-left: 0px !important;
}

.form-check-input:checked {
  cursor: pointer;
  border: none !important;
  background-color: #984df7 !important;
  box-shadow: none !important;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}
.form-check-input {
  cursor: pointer;
  border: none !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  background-color: #b7b5ba !important;
  box-shadow: none !important;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.outlet-switch-button {
  flex-direction: row !important;
}
