.draft-invoice-cell {
  background-color: $BUTTON_BORDER_GREY;
  padding: 0.5em 1em !important;
  border-radius: 100px !important;
}

.fulfilled-invoice-cell {
  background-color: $BLUE_COLOR;
  padding: 0.5em 1em !important;
  border-radius: 100px !important;
  color: $WHITE_COLOR;
}

.edit-reset-icon {
  width: 2em !important;
  height: 2em !important;
}

.invoice-textarea {
  min-height: 6.5em !important;
  max-height: 6.5em !important;
}
