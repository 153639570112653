.outlet-table-style {
  .ag-theme-quartz {
    --ag-active-color: #6a18ed !important;
    .ag-header-row {
      background-color: #ffffff !important;
      color: $BLACK_COLOR !important;
      border-bottom: none;
      min-width: 100% !important;

      .customHeaderLabel {
        font-family: "neue-june-demi" !important;
      }
    }

    .ag-row {
      height: 4em !important;
      min-height: 4em !important;
      max-height: 4em !important;

      .ag-cell {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
      }

      .ag-cell:last-child {
        display: flex;
        justify-content: end;
        align-items: center;
        border-right: none;
      }
    }
  }
}

.outlet-grid-actions {
  button {
    padding: 0px;
    background-color: transparent;
    border: none;
    font-size: 1.1em;
    font-family: "neue-june-demi" !important;
    transition: 0.3s all ease;
    &:hover {
      opacity: 0.8;
    }

    .edit-icon {
      color: $BLUE_COLOR !important;
    }
    .delete-icon {
      color: $DARK_GREY;
    }
  }
  .action-buttons-seperator {
    height: 1.7em;
    width: 0.2em;
    background-color: $BLACK_COLOR;
  }
}

.outlet-grid-name {
  color: $PURPLE_COLOR;
}

.no-outlet-heading {
  font-size: 2.5em !important;
  line-height: normal !important;
  padding: 0em 2em !important;
  font-family: "neue-june-demi" !important;
}

.no-outlet-sub-heading {
  font-size: 1.3em !important;
  opacity: 0.7;
  padding: 0em 5em !important;
}

.user-name-heading {
  font-size: 1.1em !important;
  color: $BLUE_COLOR;
}
