.discount-value-div {
  background: #fcfafc;
  margin-top: 1.5em;
  border-radius: 12px;
  padding: 1.5em;
}
.select-background-control {
  .css-1xi5nyd-control {
    background-color: #f4f4f4;
  }
}
.create-discount-modal {
  .modal-content {
    background-color: white !important;
    background-color: #f4f4f4;
    border-radius: 0.75em;
    padding: 0em;
  }
}
.search-background {
  background-color: #f4f4f4;
}
.activity-date-div {
  background: #fcfafc;
  margin-top: 1.5em;
  border-radius: 12px;
  padding: 1.5em;
}
.date-picker-bg {
}

.filter-datepicker {
  .date-icon-image-container {
    height: 2em;
    margin-right: 0px;
    z-index: 2;
    color: $DARK_GREY;
    transition-duration: 0.3s;
    position: absolute;
    top: 14% !important;
    color: $LIGHT_GREY_TEXT_COLOR;
    left: 0.5em;
  }

  input {
    width: 100% !important;
    min-height: 3em !important;
    max-height: 3em !important;
    padding-left: 3em !important;
    border: 1px solid $GREY !important;
    background-color: $WHITE_COLOR !important;
    border-bottom: 2px solid $GREY !important;

    &:hover {
      border-color: $PURPLE_COLOR !important;
    }

    &:focus {
      border-color: $PURPLE_COLOR !important;
    }
  }
}

.heading-discount-value-text {
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.5em;
  letter-spacing: 0.03em;
  text-align: left;
}
.category-div {
  height: 56px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
}
