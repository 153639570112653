.modal-sale-transaction-id {
  font-size: 0.88em;
  font-weight: 600;
  line-height: 0.88em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #232226;
}
.tran-modal-items {
  min-width: 76px;
  width: auto;
  padding: 8px;
  border-radius: 2em;

  font-size: 0.7em;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03em;
  text-align: left;
  background: #6a18ed1a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.57em;
}
.trans-items-modal {
  font-size: 0.87em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #918e95;
}
.trans-product-name-modal {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0.04em;
  text-align: left;
  margin-left: 0.5em;
  // margin-right: 0.5em;
  margin-bottom: 0px;
  color: #232226;
  max-width: 20em !important;
}

.invoice-view-product-name {
  font-size: 1.3em !important;
}

.trans-product-name-modal-total {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #232226;
}

.view-cart-date {
  font-size: 1em !important;
}

.trans-product-name-modal-dot {
  margin-left: 0.5em;
}
.trans-product-name-modal-text {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 0px;
  color: #232226;
}
.tran-modal-detail-margin {
  //   padding-right: 2.3em !important;
}
.tran-modal-total-amount-div {
  padding: 1em 0.5em;
  border-radius: 0.5em;
  background: #eae8ec;
}
.trans-items-modal-total-text {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2em;
  letter-spacing: 0.03em;
  text-align: left;
  color: #46454b;
}
.note-text-div {
  padding: 1em 0.5em;
  border-radius: 0.5em;
  background: #eae8ec;
  max-height: 100px;
}
.note-text {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.4em;
  letter-spacing: 0.02em;
  text-align: left;
  color: #333;
  word-spacing: 0.1em;
}
.trans-modal-card-wrapper {
  max-height: 160px;
  // padding-right: 10px !important;
}

.dropdown-max-width {
  max-width: 25em !important;
  width: 100%;
}
.export-button-max-width {
  max-width: 15em !important;
  width: 100%;
}

.history-view-icon {
  width: 1.5em !important;
}

.history-cart-table-header {
  font-weight: 700 !important;
  font-size: 1.1em !important;
}
