.main-purple-button {
  background-color: $PURPLE_COLOR;
  color: $WHITE_COLOR;
  border-radius: 0.75em;
  padding: 0 1.25em;
  border: 1px solid $PURPLE_COLOR;
  border-bottom: 2px solid $PURPLE_COLOR;
  transition: all 0.2s ease-in-out;
  text-wrap: nowrap;
  min-width: 10em;
  min-height: 3em;
  max-height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
}

.button-with-icon {
  padding-right: 1.41em;
}

.main-purple-button:hover {
  background-color: $TRANSPARENT;
  color: $PURPLE_COLOR;
  border-color: $PURPLE_COLOR;
}

.ghost-cancel-button {
  background-color: $TRANSPARENT;
  color: $DARK_GREY;
  border-color: $BUTTON_BORDER_GREY;
}

.ghost-cancel-button:hover {
  color: $GREY;
  border-color: $DARK_GREY;
  background-color: $DARK_GREY;
}

.light-purple-button {
  background-color: $LIGHT_PURPLE_COLOR;
  border-color: $LIGHT_PURPLE_BORDER_COLOR !important;
  color: $PURPLE_COLOR;
}

.light-purple-button:hover {
  background-color: $PURPLE_COLOR;
  color: $WHITE_COLOR;
}

.button-icon-image {
  width: 1.5em !important;
  min-width: 1.5em !important;
  height: 1.5em !important;
}

.action-buttons {
  border: none;
  justify-content: start !important;
  background-color: transparent;
  color: $DARK_GREY;
  font-size: 1em !important;
  text-wrap: nowrap;
}

.action-buttons:hover {
  color: $LIGHT_GREY_TEXT_COLOR;
}

.disable-button {
  color: $WHITE_COLOR !important;
  background-color: $BUTTON_BORDER_GREY !important;
  border-color: $BUTTON_BORDER_GREY !important;
  cursor: not-allowed;
}

.disabled-transaprent-button {
  cursor: not-allowed;
}

.disable-button:hover {
  border-color: transparent;
  background-color: $BUTTON_BORDER_GREY !important;
  border-color: $BUTTON_BORDER_GREY !important;
  color: $WHITE_COLOR;
}

.blue-button {
  background-color: $BLUE_COLOR;
  border-color: $BLUE_COLOR;
}

.blue-button:hover {
  color: $BLUE_COLOR !important;
  border-color: $BLUE_COLOR !important;
  background-color: transparent !important;
}

.gray-button {
  background-color: $GRAY_COLOR;
  border-color: $GRAY_COLOR;
}

.gray-button:hover {
  color: $BLACK_COLOR;
  border-color: $WHITE_COLOR !important;
  background-color: $WHITE_COLOR !important;
}
.red-button {
  background-color: rgb(214, 23, 23, 0.1);
  color: rgb(214, 23, 23);
  border-color: rgb(214, 23, 23, 0.5);
}

.red-button:hover {
  background-color: rgb(214, 23, 23);
  color: $WHITE_COLOR;
  border-color: rgb(214, 23, 23);
}

.transparent-button-purple {
  background-color: transparent !important;
  color: $PURPLE_COLOR !important;
  min-width: 8em;
  border: none !important;
}
.transparent-button-purple:hover {
  color: $DARK_GREY !important;
  border-color: transparent !important;
}

.transparent-button-purple:disabled {
  background-color: transparent !important;
}

.green-button {
  background-color: $GREEN_COLOR !important;
  border-color: $GREEN_COLOR !important;
}

.green-button:hover {
  background-color: transparent !important;
  color: $GREEN_COLOR;
  border-color: $GREEN_COLOR;
}

.transparent-button-ghost {
  background-color: transparent !important;
  color: $LIGHT_GREY_TEXT_COLOR !important;
  border: none !important;
  min-width: 8em;
}
.transparent-button-ghost:hover {
  color: $DARK_GREY !important;
  border-color: transparent !important;
}

.black-button {
  background-color: $BLACK_COLOR !important;
  border-color: $BLACK_COLOR;
}

.black-button:hover {
  background-color: $TRANSPARENT !important;
  color: $BLACK_COLOR;
  border-color: $BLACK_COLOR;
}
.report-bug-navbar-button {
  max-height: 2.5em !important;
  min-height: 2.5em !important;
}

.red-button-fill {
  background-color: $RED_COLOR;
  color: $WHITE_COLOR;
  border-color: $RED_COLOR;

  &:hover {
    background-color: rgb(214, 23, 23, 0.1);
    color: rgb(214, 23, 23);
    border-color: rgb(214, 23, 23, 0.5);
  }
}
