.react-data-table-wrapper,
.rdt_Table {
  width: 100%;
  margin: 0em 0 1em 0;
  background-color: $WHITE_COLOR;
  border-radius: 0.75em;
  overflow: hidden;
  max-height: 65vh;
  min-height: 30vh;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);
  // .react-table-th-con:hover {
  // }
  .react-table-header-con {
    display: flex;
    align-items: center;
    span:nth-child(1) {
      // max-width: 56px;
      max-width: 76px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      font-size: 0.875rem;
    }
  }
  .react-table-td-con {
    span,
    i {
      width: 56px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      font-size: 0.875rem;
    }
  }
}
.react-data-table {
  width: 100%;
  background-color: $WHITE_COLOR;

  thead {
    background-color: $GREY;
    color: $TABLE_HEADER_TEXT_COLOR;

    position: sticky;
    top: 0px;
    tr {
      th {
        padding: 0.75em;
      }
    }
  }

  tbody {
    max-height: 25em;
    tr {
      border-bottom: 1px solid $GREY;
      td {
        padding: 1.25em 0.75em;
        i {
          font-style: normal !important;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.scrollableWrapper,
.rdt_Table {
  overflow: auto;
  overflow-x: hidden;
}

.scrollableWrapper::-webkit-scrollbar,
.rdt_Table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollableWrapper::-webkit-scrollbar-track,
.rdt_Table::-webkit-scrollbar-track {
  background: transparent;
  position: absolute;
  right: 0;
}

.scrollableWrapper::-webkit-scrollbar-thumb,
.rdt_Table::-webkit-scrollbar-thumb {
  background: $TABLE_HEADER_TEXT_COLOR;
  border-radius: 100px;
}

.react-data-table-body {
  max-height: 10em !important;
  overflow: hidden !important;
}

.react-data-table-footer-wrapper {
  padding: 0.75em 0 0.75em 0;
  color: $LIGHT_GREY_TEXT_COLOR;
  background-color: $lightGreyBgColor;
  text-wrap: nowrap;
  margin-top: auto;
  bottom: 0;
}

.arrow-buttons {
  border: none;
  color: $DARK_GREY;
  background-color: transparent;
}

.arrow-buttons:hover {
  color: $LIGHT_GREY_TEXT_COLOR;
}

.arrow-buttons:disabled {
  cursor: not-allowed;
}

.page-index-inputField {
  width: 3em;
  height: 3em;
  text-align: center;
  border-radius: 0.75em;
  border: 1px solid transparent;
  background-color: $WHITE_COLOR;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.04),
    3px 6px 8px 0px rgba(0, 0, 0, 0.02), 7px 14px 10px 0px rgba(0, 0, 0, 0.01);
}

.page-index-inputField:focus {
  outline: none;
  border-color: $PURPLE_COLOR;
}

.page-index-inputField::-webkit-outer-spin-button,
.page-index-inputField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.action-Button-Wrapper {
  background-color: transparent;
  color: $DARK_GREY;
  border: none;
  transition: 0.5s;
}

.action-Button-Wrapper:hover {
  background-color: transparent;
  color: $TABLE_HEADER_TEXT_COLOR;
}

.user-screen-wrapper {
  header {
    background-color: transparent !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.rdt_Table {
  width: 100%;
  background-color: $WHITE_COLOR;

  .rdt_TableHead {
    .rdt_TableHeadRow {
      .rdt_TableCol {
        padding: 0.75em;
        color: $TABLE_HEADER_TEXT_COLOR;

        .rdt_TableCol_Sortable {
          span {
            display: none;
          }
        }
        .rdt_TableCol_Sortable:hover {
          opacity: 1 !important;
        }
      }
    }
  }

  .rdt_TableHeadRow {
    background-color: $GREY;
    min-height: 3.5em !important;
    max-height: 5em !important;
    input[type="checkbox"] {
      accent-color: $PURPLE_COLOR;
      width: 1em !important;
      height: 1em !important;
    }
  }

  .rdt_TableBody {
    max-height: 100%;
    scroll-margin-top: 100px;
    .rdt_TableRow {
      border-bottom: 1px solid $GREY;
      input[type="checkbox"] {
        accent-color: $PURPLE_COLOR;
        width: 1em;
        height: 1em;
      }
      .table-checkbox {
        width: 1.5em !important;
        height: 1.5em !important;
        min-width: 1.5em !important;
        min-height: 1.5em !important;
        cursor: pointer;
      }
      .rdt_TableCell {
        padding: 0.5em 0.75em;
        color: var(--bs-body-color);
        i {
          font-style: normal !important;
        }
        div:first-child {
          width: 100%;
          white-space: normal;
        }
      }
      .rdt_TableCell:last-child {
        justify-content: end;
      }
    }
  }
}

.tooltip-for-text {
  background-color: $PURPLE_COLOR !important;
  z-index: 3;
  min-width: 5em !important;
  padding-left: 0.6em !important;
  padding-right: 0.5em !important;

  ::selection {
    background-color: $DARK_BLUE_TEXT_COLOR !important;
    color: $WHITE_COLOR;
  }
}
.table-header-name {
  z-index: 2;
}

.store-cell-max-size {
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: 7em;
  padding-right: 0.5em;
}

.users-cell-max-size {
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: 15em;
  padding-right: 0.5em;
}

.devices-cell-max-size {
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: 20em;
  padding-right: 0.5em;
}

.inventory-cell-max-size {
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: 10em;
  padding-right: 0.5em;
}

.editable-inventory-cell-max-size {
  // max-width: 7em;
  height: 3em !important;
  max-height: 3em !important;
  border-radius: 0.25rem;
  background-color: $lightGreyBgColor;
  padding: 0.875em 0.5em;
  margin: 0px !important;
  position: relative;
}

.product-sku {
  direction: rtl;
}

.modal-react-data-table {
  .rdt_Table {
    // max-height: 26em !important;
    max-height: 48vh !important;
    overflow-x: hidden !important;
  }
}

.duplicaterecords-cell-max-size {
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
  max-width: 25em;
  padding-right: 0.5em;
}

.editable-table {
  .rdt_TableRow {
    transition: 0.2s ease;
  }

  .rdt_TableRow:hover {
    border-radius: 0.75em !important;
    cursor: pointer !important ;
    background-color: rgb(234, 232, 236, 0.9);
  }
}

.editable-table-row-fields {
  background-color: $lightGreyBgColor;
  max-height: 3em;
  max-width: 18em;
  padding-right: 0.5em;
}

.inventory-editable-row-fields {
  max-width: 7em !important;
}

.register-input-field {
  max-width: 7em !important;
  position: relative !important;
  min-height: 3em !important;
}

.editable-table-selected-row {
  border: 1px solid #6a18ed !important;
  background-color: #fcfafc;
  border-radius: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  transition-duration: 0.2s;
}

.customer-table-height {
  .rdt_Table {
    max-height: calc(100vh - 235px) !important;
  }
}

.customers-table {
  .rdt_Table {
    max-height: calc(100vh - 225px) !important;
  }
}

.discounts-table {
  .rdt_Table {
    max-height: calc(100vh - 200px) !important;
  }
}

.inventory-table-height {
  .rdt_Table {
    max-height: calc(100vh - 345px) !important;
  }
}
.inventory-table-height-clicked {
  .rdt_Table {
    max-height: calc(100vh - 390px) !important;
  }
}

.pricechanges-table-height {
  .rdt_Table {
    min-height: 30vh;
    max-height: calc(100vh - 215px) !important;
  }
}

.pricechanges-filter-table-height {
  .rdt_Table {
    min-height: 30vh;
    max-height: calc(100vh - 250px) !important;
  }
}

.transaction-table-height {
  .rdt_Table {
    max-height: calc(100vh - 320px) !important;
  }
}
.tradeIn-table-height {
  .rdt_Table {
    max-height: calc(100vh - 255px) !important;
  }
}

.price-changes-edit-modal {
  .rdt_TableBody {
    .rdt_TableRow {
      .rdt_TableCell:last-child {
        justify-content: start !important;
      }
    }
  }
}

.inventory-cell-tags {
  min-height: 3em;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 82%;
  border-color: #b7b5ba;
  border-radius: 0.75em;
  border-style: solid;
  border-width: 1px;
  padding-left: 0.5em;
  background-color: #fcfafc;
  cursor: pointer;
}
.product-title-max-width {
  max-width: 8em;
  cursor: pointer;
}

.batch-seial-number-label {
  font-size: 0.8em;
}

.infinite-scroll-table-row {
  transition: 0.3s ease;

  &:hover {
    background-color: $GREY;
  }
}
